import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import slugify from '../util/slugify';
import { releaseType } from '../types';
import arrayHasValues from '../util/arrayHasValues';
import LinksDisplay from '../components/linksDisplay';
import { PageH1, PageH2 } from '../components/headers';

export const query = graphql`
  query releaseData($strapiId: Int!) {
    strapiReleases(strapiId: { eq: $strapiId }) {
      Title
      Description
      ReleaseDate(formatString: "YYYY-MM-DD")
      Type
      Cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      artists {
        Name
        id
      }
      Links {
        Link
        Type
      }
    }
  }
`;

// eslint-disable-next-line react/prop-types
const KeyValue = ({ Key, Value }) => (
  <div className="grid grid-cols-3 lg:grid-cols-4">
    <span className="text-gray-400">{Key}</span>
    <div className="col-span-2 lg:col-span-3">{Value}</div>
  </div>
);

const ReleaseTemplate = ({ data }) => {
  const release = data.strapiReleases;

  return (
    <Layout>
      <SEO title={release.Title} />
      <div className="text-sm md:text-lg xl:text-xl w-full">
        <div className="grid grid-cols-2">
          <div className="m-2 lg:m-4 xl:m-8">
            <div className="relative aspect-w-1 aspect-h-1">
              <GatsbyImage
                className="object-cover pointer-events-none select-none"
                image={release.Cover.localFile.childImageSharp.gatsbyImageData}
                alt={`${release.Title} cover`}
              />
            </div>
          </div>
          <div className="p-2 lg:p-4 xl:p-8 min-w-35vw">
            <PageH1>{`${release.Title}`}</PageH1>
            <div className="mt-5 text-gray-300 space-y-2">
              <KeyValue Key="Type:" Value={release.Type} />
              <KeyValue
                Key="By:"
                Value={release.artists.map((artist, i) => (
                  <>
                    <Link
                      to={`/artists/${slugify(artist.Name)}`}
                      className="px-1 underline hover:text-indigo-300 rounded focus:text-indigo-500"
                      key={artist.id}
                    >
                      {artist.Name}
                    </Link>
                    {i < release.artists.length - 1 && ', '}
                  </>
                ))}
              />
              <KeyValue Key="Released:" Value={release.ReleaseDate} />
            </div>
            <p className="mt-3 text-gray-300">{release.Description}</p>
          </div>
        </div>
        <div className="p-8">
          {arrayHasValues(release.Links) && (
            <>
              <PageH2>Available at</PageH2>
              <LinksDisplay links={release.Links} />
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

ReleaseTemplate.propTypes = {
  data: PropTypes.shape({
    strapiReleases: releaseType,
  }).isRequired,
};

export default ReleaseTemplate;
