import React from 'react';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { linksType } from '../types';

const LinksDisplay = ({ links }) => (
  <div className="flex flex-wrap gap-y-3 gap-x-5 select-none">
    {links.map((link) => (
      <a
        href={link.Link}
        key={link.Type}
        target="_blank"
        className="text-base md:text-lg lg:text-2xl text-gray-400 box-border p-2 rounded-xl border-2 border-dashed border-gray-700 hover:border-emerald-400 focus:bg-lighter-bg"
        rel="noreferrer noopener"
      >
        <span className="inline-flex items-center justify-between">
          <span>{link.Type}</span>
          <ExternalLinkIcon className="text-gray-600 w-5 h-5 ml-3" />
        </span>
      </a>
    ))}
  </div>
);
LinksDisplay.propTypes = {
  links: PropTypes.arrayOf(linksType).isRequired,
};

export default LinksDisplay;
